<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <form-edit
          :form="saleReturn"
          :customers="customers"
          :accounts="accounts"
          :listInvoice="listInvoice"
          ref="formEdit"
          v-if="!edit"
        ></form-edit>
        <detail :id="id" :form="saleReturn" :listInvoice="listInvoice" v-if="edit"></detail>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-btn color="success" @click="updateItem">
              <v-icon>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-btn color="success" @click="editItem">
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-btn color="error" @click.stop="deleteDialog">
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="warning" v-on="on" @click="print">
                  <v-icon>
                    mdi-printer
                  </v-icon>
                </v-btn>
              </template>
              <span>Nota Return</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-delete
      :id="id"
      :dialog="dialog"
      :invoiceNumber="saleReturn.returnNumber"
      title="Sale Return"
      @deleteItem="deleteItem"
      @close="close"
    ></dialog-delete>
  </form>
</template>

<script>
import Detail from "./DetailSaleReturn";
import FormEdit from "./FormSaleReturn";
import DialogDelete from "@/components/DialogDelete";
import { mapState } from "vuex";

export default {
  name: "sale-return-view",
  components: {
    Detail,
    "form-edit": FormEdit,
    "dialog-delete": DialogDelete,
  },
  props: {
    id: Number,
  },
  data: () => ({
    edit: true,
    dialog: false,
    listInvoice: [],
    accounts: [],
    saleReturn: {},
  }),
  computed: {
    ...mapState("external", ["customers"]),
  },
  methods: {
    async getSaleReturn() {
      await this.$store.dispatch("saleReturn/getById", this.id).then(async response => {
        this.saleReturn = response.data;
        if (response.data.downPayment) {
          this.getListInvoiceDp(response.data.customerId);
        } else {
          this.getListInvoice(response.data.customerId);
        }
      });
    },
    async getListInvoice(id) {
      const response = await this.$store.dispatch("saleReturn/getListInvoice", id);
      if (response.status === 200) {
        this.listInvoice = response.data;
      }
    },
    async getListInvoiceDp(id) {
      const response = await this.$store.dispatch("saleReturn/getListInvoiceDP", id);
      if (response.status === 200) {
        this.listInvoice = response.data;
      }
    },
    async getAccounts() {
      const response = await this.$store.dispatch("chartOfAccount/getAccounts");
      if (response.status === 200) {
        this.accounts = response.data;
      }
    },
    back() {
      this.$router.go(-1);
    },
    editItem() {
      this.edit = false;
    },
    updateItem() {
      this.$refs.formEdit.update();
    },
    deleteItem(id) {
      this.$store.dispatch("saleReturn/delete", id);
      this.dialog = false;
    },
    deleteDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    cancel() {
      this.getSaleReturn();
      this.edit = true;
    },
    print() {
      this.$router.push({ name: "Nota Return", params: { id: this.id } });
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        this.getSaleReturn(),
        await this.$store.dispatch("external/getCustomers"),
        this.getAccounts(),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style></style>
